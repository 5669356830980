<template>
  <router-view></router-view>
</template>

<script>
const DEFAULT_TITLE = "Dashboard";
import updateToken from "@/auth/updatetoken";
export default {
  name: "App",
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        updateToken();
        document.title = to.name || DEFAULT_TITLE;
      },
    },
  },
};
process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
