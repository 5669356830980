import Vue from 'vue';


export default async function () {
  try {
    await Vue.$keycloak.updateToken(70);
  }
  catch (err) {

  }
  return Vue.$keycloak.token;
}