<template>
  <masked-input
    :guide="guide"
    :keepCharPositions="keepCharPositions"
    :mask="numberMask"
    :type="type"
    :name="name"
    v-bind:class="classes"
    v-model="context.model"
    :placeholder="placeholder">
  </masked-input>
</template>

<script>
import MaskedInput from "vue-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

export default {
  name: "vueFormulateCurrency",
  components: {
    MaskedInput,
  },

  props: {
    context: {
      type: Object,
      required: true,
    },
  },

  computed: {
    // number mask options
    prefix() {
      return this.context.attributes.prefix || "";
    },
    includeThousandsSeparator() {
      return this.context.attributes.includeThousandsSeparator || true;
    },
    thousandsSeparatorSymbol() {
      return this.context.attributes.thousandsSeparatorSymbol || ",";
    },
    allowDecimal() {
      return this.context.attributes.allowDecimal || true;
    },
    decimalSymbol() {
      return this.context.attributes.decimalSymbol || ".";
    },
    decimalLimit() {
      return this.context.attributes.decimalLimit || 0;
    },
    allowNegative() {
      return this.context.attributes.allowNegative || true;
    },
    allowDecimalNumbers() {
      return this.context.attributes.allowDecimalNumbers || true;
    },
    // input mask options
    model() {
      return this.context.model;
    },
    guide() {
      return this.context.attributes.guide || false;
    },
    keepCharPositions() {
      return this.context.attributes.keepCharPositions || false;
    },
    type() {
      return this.context.attributes.type || "text";
    },
    name() {
      return this.context.attributes.name || "";
    },
    numberMask() {
      return createNumberMask({
        prefix: this.prefix,
        includeThousandsSeparator: this.includeThousandsSeparator,
        thousandsSeparatorSymbol: this.thousandsSeparatorSymbol,
        allowDecimal: this.allowDecimal,
        decimalSymbol: this.decimalSymbol,
        decimalLimit: this.decimalLimit,
        allowNegative: this.allowNegative,
        allowDecimalNumbers: this.allowDecimalNumbers,
      });
    },
    placeholder() {
      return this.context.attributes.placeholder || "";
    },

    classes() {
      const defaultClasses = Array.isArray(this.context.attributes.class)
        ? this.context.attributes.class
        : [];
      const addedClasses = Array.isArray(this.context.attributes.styling)
        ? this.context.attributes.styling
        : [];

      const classes = [...defaultClasses, ...addedClasses].join(" ");

      return classes;
    },
  },
};
</script>
