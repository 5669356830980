import "core-js/stable";
import runtime from "regenerator-runtime/runtime";
import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import authentication from "@/auth/keycloak-plugin.js";
import updateToken from "@/auth/updatetoken";
import CoreuiVue from "@coreui/vue";
import CoreuiVueCharts from "@coreui/vue-chartjs";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { freeSet as icons } from "@coreui/icons";
import VueGoodTablePlugin from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import Chartkick from "vue-chartkick";
import Chart from "chart.js/auto";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueClipboard from "vue-clipboard2";
import swal from "sweetalert";
import numeral from "numeral";
import Cleave from "cleave.js";
import numFormat from "vue-filter-number-format";
import VueFormulate from "@braid/vue-formulate";
import FormulateVSelectPlugin from "@cone2875/vue-formulate-select";

import vueFormulateCurrency from "@/components/mask/vueFormulateCurrency";

Vue.component("vueFormulateCurrency", vueFormulateCurrency);

//numeral.zeroFormat(0);

Vue.use(runtime);
Vue.use(authentication);
Vue.use("sweetalert", swal);
Vue.use(VueClipboard);
Vue.use(VueFormWizard);
Vue.use(VueGoodTablePlugin);
Vue.use(Chartkick.use(Chart));
Vue.use(CoreuiVueCharts);
Vue.use(CoreuiVue);
Vue.use(BootstrapVue);
Vue.component("v-select", vSelect);
Vue.component("loading-overlay", Loading);
Vue.filter("numFormat", numFormat(numeral));

Vue.use(VueFormulate, {
  // Buscar darle una segunda vuelta a context functions,
  //intentar no utilizar el context para volver mantenible el codigo
  classes: {
    wrapper(context) {
      switch (context.classification) {
        case "box":
          return "wrapper-checkbox";
        default:
          return "";
      }
    },
    label(context) {
      switch (context.classification) {
        case "box":
          return "px-2";
        default:
          return "";
      }
    },
    input(context) {
      switch (context.classification) {
        case "box":
          return "input-checkbox";
        // case "button": este case busca una data-classification igual a button
        // y devuelve las clases correspondientes para el boton
        case "button":
          return "formulate-input-element button ";
        default:
          return "form-control field-border-bottom-only mb-2";
      }
    },
    inputHasErrors: "is-invalid",
    help: "form-text text-muted",
    errors: "list-unstyled text-danger",
    form: "d-flex flex-column flex-sm-row flex-wrap",
    groupRepeatable: "d-flex flex-column flex-sm-row flex-wrap",
    groupRepeatableRemove: "formulate-input-group-repeatable-remove mr-3",
  },
  locales: {
    en: {
      required() {
        return `Required`;
      },
    },
  },
  library: {
    currency: {
      classification: "currency",
      component: "vueFormulateCurrency",
    },
  },
  plugins: [FormulateVSelectPlugin],
});
// For performance debugging in Chrome dev tools
Vue.config.performance = true;

Vue.directive("cleave", {
  inserted: (el, binding) => {
    el.cleave = new Cleave(el, binding.value || {});
  },
  update: (el) => {
    const event = new Event("input", { bubbles: true });
    setTimeout(function () {
      //el.value = el.cleave.properties.result; // Analizar si es necesario a largo plazo.
      el.dispatchEvent(event);
    }, 100);
  },
});

Vue.$keycloak
  .init({ onLoad: "login-required", checkLoginIframe: false })
  .then((authenticated) => {
    if(authenticated) {
        new Vue({
          store,
          icons,
          router,
          render: (h) => h(App),
        }).$mount("#app");
    
        window.onfocus = () => {
          updateToken();
        };
    }
  });
