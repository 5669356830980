import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");

const SecurityOverview = () =>
  import("@/views/portfolios/views/securityOverview");

const AdvancedChartView = () =>
  import("@/views/portfolios/views/advancedChartView");

//Portfolios
const Graphics = () => import("@/views/portfolios/views/Graphics");
const PortfolioView = () => import("@/views/portfolios/views/PortfolioView");
const Positions = () => import("@/views/portfolios/views/positions");
const Summary = () => import("@/views/portfolios/views/SummaryView");
const PortfolioPositionEdit = () =>
  import("@/views/portfolios/views/PortfolioPositionEdit");

const PositionClosingView = () =>
  import("@/views/portfolios/views/positionClosingView");

const Balances = () => import("@/views/portfolios/account/balances");
const Orders = () => import("@/views/portfolios/account/ordersWrapper");
const Transfers = () => import("@/views/portfolios/account/transfers");

const FormAddNew = () => import("@/views/portfolios/account/FormAddNew.vue");
const Reports = () => import("@/views/portfolios/reports");
//tools
//tradewizard
const BuyWizard = () => import("@/views/portfolios/tools/BuyWizard");
const WizardComponent = () =>
  import("@/views/portfolios/tools/WizardComponent");
const WizardComponent3 = () =>
  import("@/views/portfolios/tools/WizardComponent3");
const WizardComponent4 = () =>
  import("@/views/portfolios/tools/WizardComponent4");
const WizardComponent5 = () =>
  import("@/views/portfolios/tools/WizardComponent5");

const SellWizard = () => import("@/views/portfolios/tools/SellWizard");
const WizardCustomComponent = () =>
  import("@/views/portfolios/tools/WizardCustomComponent");

const Securities = () => import("@/views/portfolios/tools/securities");
const Prices = () => import("@/views/portfolios/tools/prices");

//ACCOUNTING
const Journal = () => import("@/views/accounting/journal");
const Ledger = () => import("@/views/accounting/ledger");

const FinancialPosition = () =>
  import("@/views/accounting/reports/financialposition");
const ComprehensiveIncome = () =>
  import("@/views/accounting/reports/comprehensiveincome");
const CashFlow = () => import("@/views/accounting/reports/cashflow");

const View = () => import("@/views/accounting/accounts/view");
const AddNew = () => import("@/views/accounting/accounts/add");
const AccountForm = () => import("@/views/accounting/accounts/AccountForm");
//DOCUMENTS
const Login = () => import("@/views/documents/login");

//STP MESSAGES
const VPending = () => import("@/views/stp/view/pending");
const VImported = () => import("@/views/stp/view/imported");
const VAll = () => import("@/views/stp/view/all");

const importt = () => import("@/views/stp/import");

const PPending = () => import("@/views/stp/positions/pending");
const PArchived = () => import("@/views/stp/positions/archived");

const OPending = () => import("@/views/stp/operations/OPending");
const OArchived = () => import("@/views/stp/operations/archived");
Vue.use(Router);

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/",
      name: "Home",
      component: TheContainer,
      children: [
        {
          path: "",
          name: "",
          component: Dashboard,
        },
        {
          path: "/:portfolioType/:portfolioName/statement",
          name: "Portfolio View",
          component: PortfolioView,
          Graphics,
        },

        {
          path: "/portfolios/",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              name: "",
              path: "statement",
              component: {
                template: `
                    <div>
                        <router-view></router-view>
                    </div>
                    `,
              },
              children: [
                {
                  name: "Portfolio View",
                  component: PortfolioView,
                  Graphics,
                  path: "",
                },
                {
                  name: "Edit Position",
                  path: "editposition/assetId/:asset_id",
                  component: PortfolioPositionEdit,
                },
              ],
            },
            {
              name: "",
              path: "position",
              component: {
                template: `
                    <div>
                        <router-view></router-view>
                    </div>
                    `,
              },
              children: [
                {
                  name: "Position Closing",
                  path: "close",
                  component: PositionClosingView,
                },
              ],
            },
            {
              path: "views/",
              name: "Views",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "positions",
                  name: "New Position",
                  component: Positions,
                },
                {
                  path: "summary",
                  name: "Summary View",
                  component: Summary,
                },
                {
                  path: "/security-overview",
                  name: "SecurityOverview",
                  component: SecurityOverview,
                },
                {
                  path: "/advance-chart-view",
                  name: "AdvancedChartView",
                  component: AdvancedChartView,
                },
              ],
            },
            {
              path: "account/",

              name: "Account",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "balances",
                  name: "",
                  redirect: "balances",
                  component: {
                    template: `
    <div>

      <router-view></router-view>
    </div>
  `,
                  },
                  children: [
                    {
                      name: "Balances",
                      component: Balances,
                      path: "",
                    },
                    {
                      name: "Add New Balance",
                      component: FormAddNew,
                      path: "addnew",
                    },
                  ],
                },
                {
                  path: "orders",
                  name: "",
                  redirect: "orders",
                  component: {
                    template: `
              <div>

                <router-view></router-view>
              </div>
            `,
                  },
                  children: [
                    {
                      name: "Orders",
                      component: Orders,
                      path: "",
                    },
                    {
                      name: "Add New Order",
                      component: FormAddNew,
                      path: "addnew",
                    },
                  ],
                },
                {
                  path: "transfers/",
                  name: "transfers",
                  redirect: "transfers",
                  component: {
                    template: `
          <div>

            <router-view></router-view>
          </div>
        `,
                  },
                  children: [
                    {
                      name: "Transfers",
                      component: Transfers,
                      path: "",
                    },
                    {
                      name: "Add New Transfer",
                      component: FormAddNew,
                      path: "addnew",
                    },
                  ],
                },
              ],
            },
            {
              path: "reports",
              name: "Reports",
              component: Reports,
            },
            {
              path: "tools/",

              name: "Tools",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "wizard/buyorder/",
                  name: "Buy Wizard",
                  component: BuyWizard,
                  WizardComponent,
                  WizardComponent3,
                  WizardComponent4,
                  WizardComponent5,
                },
                {
                  path: "wizard/sellorder/",
                  name: "Sell Wizard",
                  component: SellWizard,
                  WizardComponent,
                  WizardCustomComponent,
                },
                {
                  path: "securities/",
                  name: "",
                  redirect: "securities",
                  component: {
                    template: `
          <div>

            <router-view></router-view>
          </div>
        `,
                  },
                  children: [
                    {
                      name: "Securities Master",
                      component: Securities,
                      path: "",
                    },
                    {
                      name: "Add New Security",
                      component: FormAddNew,
                      path: "addnew",
                    },
                  ],
                },
                {
                  path: "prices/",
                  name: "",
                  redirect: "prices",
                  component: {
                    template: `
          <div>

            <router-view></router-view>
          </div>
        `,
                  },
                  children: [
                    {
                      name: "OTC Pricing Entry",
                      component: Prices,
                      path: "",
                    },
                    {
                      name: "Add New Price",
                      component: FormAddNew,
                      path: "addnew",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          path: "/accounting/",

          name: "Accounting",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "journal",
              name: "Journal",
              component: Journal,
            },
            {
              path: "/accounting/ledger",
              name: "Ledger",
              component: Ledger,
            },
            {
              path: "reports/",

              name: "Reports ",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "financialposition",
                  name: "Financial Position",
                  component: FinancialPosition,
                },
                {
                  path: "comprehensiveincome",
                  name: "Comprehensive Income",
                  component: ComprehensiveIncome,
                },
                {
                  path: "cashflow",
                  name: "Cash Flow",
                  component: CashFlow,
                },
              ],
            },
            {
              path: "accounts/",

              name: "Accounts",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "view",
                  name: "View",
                  component: View,
                },
                {
                  path: "add",
                  name: "Add New",
                  component: AccountForm,
                },
              ],
            },
          ],
        },
        {
          path: "/documents/",

          name: "Documents",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "login",
              name: "Login",
              component: Login,
            },
          ],
        },

        {
          path: "/stp/",

          name: "STP MESSAGES",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "view/",

              name: "View ",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "pending",
                  name: "VPending",
                  component: VPending,
                },
                {
                  path: "Imported",
                  name: "Imported",
                  component: VImported,
                },
                {
                  path: "all",
                  name: "All",
                  component: VAll,
                },
              ],
            },
            {
              path: "import",
              name: "Import",
              component: importt,
            },
            {
              path: "operations/",
              name: "Operations",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "pending",
                  name: "Pending ",
                  component: OPending,
                },
                {
                  path: "archived",
                  name: "Archived ",
                  component: OArchived,
                },
              ],
            },
            {
              path: "positions/",

              name: "Positions ",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "pending ",
                  name: "Pending",
                  component: PPending,
                },
                {
                  path: "archived",
                  name: "Archived",
                  component: PArchived,
                },
              ],
            },
          ],
        },
      ],
    },
  ];
}
