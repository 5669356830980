import {
  portfolioInstance,
  accountingInstance,
  pricingInstance,
  wizardInstance,
} from "./index.js";

const defaultServerParameters = {
  page: 1,
  perPage: 25,
};

/*
 * Portfolio API
 */
export const getTransfers = () => portfolioInstance.get(`transfers`);
export const getBalances = (serverParameters = defaultServerParameters) =>
  portfolioInstance.get("balances", {
    params: serverParameters,
  });
export const serverSideTableGetter = (
  storePath,
  serverParameters = defaultServerParameters
) =>
  portfolioInstance.get(storePath, {
    params: serverParameters,
  });
export const getOrders = () => portfolioInstance.get("orders");
export const getCurrencies = () => accountingInstance.get("currencies");
export const getCompanies = () => accountingInstance.get("companies");
export const getAccounts = () => accountingInstance.get("accounts");
export const getPortfolios = () => portfolioInstance.get("portfolios");

export const getSummaryView = async function (portfolioType, portfolioId) {
    if(portfolioType == '' && portfolioId == '') {
        return portfolioInstance.get(`reports/accounts_summary`);
    } else {
        return portfolioInstance.get(`reports/accounts_summary/${portfolioType}/${portfolioId}`);
    }
}

export let getCustomPortfolio = (uri) =>
  portfolioInstance.get(`clients/${uri}/portfolios`);
export let getPortfolioDetail = (uri) =>
  portfolioInstance.get(`portfolios/${uri}/positions`);
export let getClientDetail = (uri) =>
  portfolioInstance.get(`clients/${uri}/positions`);
export const getSecurityByID = (id) =>
  portfolioInstance.get(`securities/${id}`);
export const getCurrencyByID = (id) =>
  portfolioInstance.get(`currencies/${id}`);
export const getPrices = () => portfolioInstance.get("prices");
export let editItem = (uri, objectToEdit) =>
  portfolioInstance.put(uri, objectToEdit);
export let postItem = (uri, objectToPost) =>
  portfolioInstance.post(uri, objectToPost);
export let deleteItem = (uri) => portfolioInstance.delete(uri);
export let getCurrencyIsd = (uri, currencies) =>
  portfolioInstance.get(uri, currencies);
export let createPortfolioPositions = (uri, objectToPost) =>
  portfolioInstance.post(uri, objectToPost);
export let getPortfolioPositionsByAssetId = (assetId, portfolioId) =>
  portfolioInstance.get(
    `positions?columnFilters={"Portfolio_ID":"${portfolioId}","Asset_ID":"${assetId}"}`
  );
export let getClientPositionsByAssetId = (assetId, clientId) =>
  portfolioInstance.get(
    `positions?columnFilters={"Client_ID":"${clientId}","Asset_ID":"${assetId}"}`
  );
export let editPortfolioPosition = (id, objectToEdit) =>
  portfolioInstance.put(`positions/${id}`, objectToEdit);
export let closePortfolioPosition = (positionToClose) =>
  portfolioInstance.post(`positions/close`, positionToClose);
export let getPortfolioPerformance = (uri) =>
  portfolioInstance.get(`portfolios/${uri}/performance`);
export let getClientPerformance = (uri) =>
  portfolioInstance.get(`clients/${uri}/performance`);
export const getClients = () =>
  portfolioInstance.get("clients").then((response) => {
    const results = [];
    results.push(response.data.data);
    return results;
  });
export const getBanks = () =>
  portfolioInstance.get("banks").then((response) => {
    const results = [];
    results.push(response.data.data);
    return results;
  });
export const getSecurities = (serverparam) =>
  portfolioInstance
    .get("securities", { params: serverparam })
    .then((response) => {
      const results = [];
      results.push(response.data.data.rows);
      return results;
    });

/*
 * Order Wizard API
 */
export let postMockApi = (uri, objectToPost) =>
  wizardInstance.post(uri, objectToPost);

/*
 * Pricing API
 */
export const getExchanges = () => pricingInstance.get("exchange");

export const getSecurityLastPrice = (security) =>
  pricingInstance.get("security/price/last", {
    params: {
      securities: security,
      vendor: "Bloomberg",
      style: "full",
      showAll: true,
    },
  });

export const getCurrencyLastPrice = (currency) =>
  pricingInstance.get("currency/price/last", {
    params: {
      currencies: currency,
      style: "full",
      vendor: "Bloomberg",
      showAll: true,
    },
  });

export const getSecurityHistoricalPrice = (
  security,
  fromDate = "",
  toDate = "",
  periodicity = "daily"
) =>
  pricingInstance.get("security/price/historical", {
    params: {
      securities: security,
      fromDate: fromDate,
      toDate: toDate,
      periodicity: periodicity,
      vendor: "Bloomberg",
      style: "full",
      showAll: true,
    },
  });

export const getSecurityReturnToDate = (security, dates) =>
  pricingInstance.get("security/returnToDate", {
    params: {
      securities: security,
      dates: dates,
      vendor: "Bloomberg",
      showAll: true,
    },
  });
