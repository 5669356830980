import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import {
  getSecurityLastPrice,
  getSecurityHistoricalPrice,
  getSecurityReturnToDate,
  getPortfolioPerformance,
  getPortfolioDetail,
  getSecurities,
  getCurrencies,
  getPortfolios,
} from "./services/api.js";
Vue.use(Vuex, axios);

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
  security: [], //Data from API MOCK

  securitiesList: [],
  currenciesList: [],

  PortfolioData: [], //Data from Portfolios
  portfolio_position: [],
  portfolio_position_selected: "",
  portfolio_performance: [],
  portfolioList: [],
  selectedPortfolio: "",
  selectedPortfolioType: "",
  assetIdentifier: "",
  securityLastPrice: "",
  securityHistoricalPrice: "",
  securityReturnToDate: "",

  closingFormSelectedPortfolio: "",
  closingFormSelectedAsset: "",
  closingFormSelectedPosition: "",

  getSecurity: {},
  form_clients: [],
  form_securities: [],
  form_assets: {},
  //positions
  positions: [],
  //trade wizard(buy)
  form_porfolioList: {},
  form_porfolioList_updated: {},
  form_counter: [],
  form_post: {},
  form_response: {},

  //sellwizard
  sell_assets: {},
  sell_clients: {},
  sell_banks: {},
  sell_portfolios: [],
  sell_post: {},

  //account: transfers balances orders
  table_transfers: [],
  table_balances: [],
  table_orders: [],
  table_editableFields: [],
  table_editableObject: {},
  table_field: "",
  table_unique_key: [],
  table_edit_mode: false,

  table_securities: [],
  table_prices: [],
};
const actions = {
  loadSecuritiesList({ commit }) {
    if(state.securitiesList.length == 0) {
      getSecurities().then((response) => {
        commit("SET_SECURITIES_LIST", response[0]);
      });
    }
  },
  loadCurrenciesList({ commit }) {
    if(state.currenciesList.length == 0) {
      getCurrencies().then((response) => {
        commit("SET_CURRENCIES_LIST", response.data.data);
      });
    }
  },
  loadPortfoliosList({ commit }) {
    if(state.portfolioList.length == 0) {
      getPortfolios().then((response) => {
        commit("SET_PORTFOLIOS_LIST", response.data.data);
      });
    }
  },
  loadLastSecurityDataPrice({ commit }) {
    commit("SET_SECURITY_LASTPRICE", {});
    getSecurityLastPrice(state.assetIdentifier).then((response) => {
      commit("SET_SECURITY_LASTPRICE", response.data);
    });
  },

  loadHistoricalSecurityDataPrice({ commit }) {
    commit("SET_SECURITY_HISTORICALPRICE", {});
    var to = new Date();
    var from = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    var toDate = to.toISOString().slice(0, 10).replace(/-/g, "");
    var fromDate = from.toISOString().slice(0, 10).replace(/-/g, "");

    getSecurityHistoricalPrice(state.assetIdentifier, fromDate, toDate).then(
      (response) => {
        commit("SET_SECURITY_HISTORICALPRICE", response.data);
      }
    );
  },

  loadSecurityReturnToDate({ commit }) {
    commit("SET_SECURITY_RETURNTODATE", {});
    var dates = new Date().toISOString().slice(0, 10).replace(/-/g, "");
    getSecurityReturnToDate(state.assetIdentifier, dates).then((response) => {
      commit("SET_SECURITY_RETURNTODATE", response.data);
    });
  },

  updatePortfolioView({ commit }) {
    commit("SET_PORTFOLIODATA", []);
    getPortfolioDetail(state.selectedPortfolio).then((res) => {
      // Refactor to getPortfolioPositionsByAssetId
      let payload = res.data.data;
      payload.map((element) => (element.uuid = uuidv4()));
      commit("SET_PORTFOLIODATA", payload); // Refator PORTFOLIO_POSITIONS
    });

    commit("SET_PORTFOLIOPERFORMANCE", {});
    getPortfolioPerformance(state.selectedPortfolio).then((res) => {
      let payload = res.data.data;
      let performanceData = {
        // We add a nullish coalescing operator because some fields are not always present un the API response.
        BalanceDate: payload.balance_date ?? null,
        InitialNetWorth: payload.balance ?? null,
        TransfersInOut: payload.transfers ?? null,
        CurrentNetWorth: payload.current_portfolio ?? null,
        ProfitAndLossInCapital: payload.pnl ?? null,
        TimeWeightedReturn: payload.hpr ?? null,
        MoneyWeightedReturn: payload.mwr ?? null,
        AnnualizedReturn: payload.ar ?? null,
      };
      // Guardo en el store los datos que me trajo el api de performance
      commit("SET_PORTFOLIOPERFORMANCE", performanceData);
    });
  },

  form_counter({ commit }) {
    commit("SET_FORM_COUNTER");
  },
  loadAssets({ commit }, payload) {
    commit("SET_FORMASSETS", payload);
  },
  loadResponse({ commit }, payload) {
    commit("SET_FORM_RESPONSE", payload);
  },
  updateForm_portfolioList({ commit }, payload) {
    commit("UPDATE_FORM_PORTFOLIOLIST", payload);
  },
  loadSellAssets({ commit }, payload) {
    commit("SET_SELLPOST", payload);
  },
  cleanWizard({ commit }) {
    commit("SET_CLEANWIZARD");
  },
  cleanSellPost({ commit }) {
    commit("SET_CLEANSELLPOST");
  },
  editPortfolioData({ commit }, { index, row, originalRow }) {
    commit("SET_PORTFOLIO_ROW", { index: index, row: row });
  },
};

const mutations = {
  SET_CURRENCIES_LIST(state, payload) {
    state.currenciesList = payload;
  },
  SET_SECURITIES_LIST(state, payload) {
    state.securitiesList = payload;
  },
  SET_ASSET_IDENTIFIER(state, payload) {
    //On-row-click assetIdentifier is set, after that the Quote data will be fetch (loadSecurityData or loadsecurityByMemory)
    state.assetIdentifier = payload;
  },
  SET_SECURITY_LASTPRICE(state, payload) {
    state.securityLastPrice = payload;
  },
  SET_SECURITY_HISTORICALPRICE(state, payload) {
    state.securityHistoricalPrice = payload;
  },

  SET_SECURITY_RETURNTODATE(state, payload) {
    state.securityReturnToDate = payload;
  },

  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },

  SET_CLOSINGFORMSELECTEDPORTFOLIO(state, payload) {
    state.closingFormSelectedPortfolio = payload;
  },
  SET_CLOSINGFORMSELECTEDASSET(state, payload) {
    state.closingFormSelectedAsset = payload;
  },
  SET_CLOSINGFORMSELECTEDPOSITION(state, payload) {
    state.closingFormSelectedPosition = payload;
  },

  SET_SELECTEDPORTFOLIO(state, payload) {
    state.selectedPortfolio = payload;
  },
  SET_SELECTEDPORTFOLIO_TYPE(state, payload) {
    state.selectedPortfolioType = payload;
  },
  SET_PORTFOLIODATA(state, payload) {
    state.PortfolioData = payload;
  },
  SET_PORTFOLIO_ROW(state, { index, row }) {
    let newData = state.PortfolioData;
    newData[index] = row;
    state.PortfolioData = [...newData];
  },

  SET_SECURITIES(state, payload) {
    state.form_securities = payload;
  },
  SET_CLIENTS(state, payload) {
    state.form_clients = payload;
  },
  SET_FORMASSETS(state, payload) {
    state.form_assets = payload;
  },
  SET_FORM_PORTFOLIOLIST(state, payload) {
    let newList = payload;
    let list = {};
    let oldWithData = Object.keys(state.form_porfolioList_updated);
    Object.keys(payload).forEach((element) => {
      oldWithData.includes(element)
        ? (list[element] = state.form_porfolioList_updated[element])
        : (list[element] = newList[element]);
    });
    state.form_porfolioList = list;
  },
  UPDATE_FORM_PORTFOLIOLIST(state) {
    state.form_porfolioList_updated = state.form_porfolioList;
  },
  SET_POST_OBJECT(state) {
    let list = Object.entries(state.form_porfolioList_updated).map(
      ([key, value]) => [
        key,
        Object.entries(value).reduce(
          (a, [k, v]) => (v ? ((a[k] = v), a) : a),
          {}
        ),
      ]
    );
    state.form_post.asset_list = Object.fromEntries(list);
    state.form_post.portfolioList = state.form_assets;
  },

  SET_FORM_PORTFOLIOLIST_UPDATED(state, payload) {
    state.form_porfolioList_updated = payload;
  },
  SET_FORM_COUNTER(state) {
    state.form_counter = Object.values(state.form_porfolioList_updated).map(
      function (object) {
        return Object.keys(object).reduce(function (sum, key) {
          return sum + Number(object[key]);
        }, 0);
      }
    );
  },
  SET_FORM_RESPONSE(state, payload) {
    state.form_response = payload;
  },
  SET_SELLASSETS(state, payload) {
    state.sell_assets = payload;
  },
  SET_SELLCLIENTS(state, payload) {
    state.sell_clients = payload;
  },
  SET_SELLBANKS(state, payload) {
    state.sell_banks = payload;
  },
  SET_SELLPORTFOLIOS(state, payload) {
    state.sell_portfolios = payload;
  },
  SET_SELLPOST(state, payload) {
    state.sell_post = payload;
  },
  SET_CLEANWIZARD(state) {
    state.form_clients = [];
    state.form_securities = [];
    state.form_assets = {};
    state.form_porfolioList = {};
    state.form_porfolioList_updated = {};
    state.form_counter = [];
    state.form_post = {};
    state.form_response = {};
    state.sell_assets = {};
    state.sell_clients = {};
    state.sell_banks = {};
    state.sell_portfolios = [];
    state.sell_post = {};
  },
  SET_CLEANSELLPOST(state) {
    state.sell_post = {};
  },
  SET_TABLE_TRANSFERS(state, payload) {
    state.table_transfers = payload;
  },
  SET_TABLE_BALANCES(state, payload) {
    state.table_balances = payload;
  },
  SET_TABLE_ORDERS(state, payload) {
    state.table_orders = payload;
  },
  SET_TABLE_EDITABLEFIELDS(state, payload) {
    state.table_editableFields = payload;
    let PreEditableObject = payload
      .map((item) => item.field)
      .map((element) => {
        return [element, ""];
      });
    state.table_editableObject = Object.fromEntries(PreEditableObject);
  },
  SET_TABLE_UNIQUE_KEY(state, payload) {
    state.table_unique_key = payload;
  },
  Add_acountItem(state, id) {
    let lastString = state.table_field;
    let field = "table_" + lastString;
    let key = state.table_unique_key;

    let newItem = { ...state.table_editableObject };

    newItem[key] = "a" + id; //despuesponerUUID aca
    newItem.newitem = true;
    state.table_edit_mode == true
      ? console.log("hasta no dejar de editar no se agregan items")
      : state[field].unshift(newItem);

    state.table_edit_mode = true;
  },
  ADD_ACOUNT_ITEM_FROM_FORM(state, payload) {
    let field = `table_${payload.uri}`;

    state[field].push(payload.obj);
  },
  SET_TABLE_FIELD(state, payload) {
    state.table_field = payload;
  },
  SET_TABLE_EDIT_MODE(state, payload) {
    state.table_edit_mode = payload;
  },
  SET_TABLE_SECURITIES(state, payload) {
    state.table_securities = payload;
  },
  SET_TABLE_PRICES(state, payload) {
    state.table_prices = payload;
  },
  SET_POSITIONS(state, payload) {
    state.positions = payload;
  },
  SET_PORTFOLIOPOSITION(state, payload) {
    state.portfolio_position = payload;
  },
  SET_PORTFOLIOPOSITION_SELECTED(state, payload) {
    state.portfolio_position_selected = payload;
  },
  SET_PORTFOLIOPERFORMANCE(state, payload) {
    state.portfolio_performance = payload;
  },
  SET_SUMMARY_VIEW(state, payload) {
    state.summary_view = payload;
  },
  SET_PORTFOLIOS_LIST(state, payload) {
    state.portfolioList = payload;
  },
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
});
