import Vue from "vue";

function errorResponseHandler(error) {
  // check for errorHandle config
  if (
    error.config.hasOwnProperty("errorHandle") &&
    error.config.errorHandle === false
  ) {
    //Check for error handle property in request config, by default errorHandle = true
    return Promise.reject(error);
  }

  // if has response show the error
  if (error.response) {
    const vm = new Vue();
    vm.$bvToast.toast([error.response.data.message], {
      title: `Code: ${error.response.status || "default"}`,
      variant: "danger",
      solid: true,
      toaster: "b-toaster-top-center",
    });
  }
}

export default errorResponseHandler;
