import axios from "axios";
import updateToken from "@/auth/updatetoken";
import errorResponseHandler from "./errorHandler";

export const portfolioInstance = axios.create({
  baseURL: "https://api.pms.demo.southecon.net/api/v1/",
  headers: {
    "x-api-key": "boguskey",
  },
});

export const accountingInstance = axios.create({
  baseURL: "https://artemis.ch.southecon.net/accounting/api/v1/",
  auth: {
    username: "South",
    password: "FXHSky2",
  },
  headers: {
    "x-api-key": "boguskey",
  },
});
export const securityInstance = axios.create({
  baseURL: "http://localhost:3000/",
});

export const wizardInstance = axios.create({
  baseURL: "https://positionsclose.free.beeceptor.com/api/v1",
  auth: {},
  headers: {},
});

export const pricingInstance = axios.create({
  baseURL: "http://10.200.10.34:8090/",
});

pricingInstance.interceptors.request.use(async (config) => {
  const token = await updateToken();
  config.headers.common["Authorization"] = `Bearer ${token}`;
  return config;
});

portfolioInstance.interceptors.request.use(async (config) => {
  const token = await updateToken();
  config.headers.common["Authorization"] = `Bearer ${token}`;
  return config;
});

portfolioInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response;
  },
  function (error) {
    if (error.response.status != "404") errorResponseHandler(error);
    else return error.response;
  }
);
